import React from 'react';
import { Link, graphql } from "gatsby";
import AOS from 'aos';

import PrimaryLayout from "../layouts/PrimaryLayout"
import SEO from '../layouts/SEO';


const Tags = (props) => {
  
    
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
    if(!props.location.state){
      window.location = '/'
    }
  }

  if(!props.location.state) return null;

  const post = props.data.allWordpressPost.nodes;
  // const location = props.location.state.tagPath;
  const tagDescription = props.location.state.tagDescription;
  // console.log(post);
  const page = props.data.wordpressPage;


  return (
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <section id="primary" className="content-area">
        <main id="main" className="site-main">
          <div className="container-fluid">
            <div className="row p-0 m-0">
              <div className="container main-container-padding-t-5 main-container-padding-b-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="header-text-container">
                      <div className="vertical-center">
                        <h1 data-aos="fade-up">Tag: <br /><span dangerouslySetInnerHTML={{__html: props.location.state.tagName}}/></h1>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div data-aos="fade-up" dangerouslySetInnerHTML={{ __html: tagDescription }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {post.map((node, index) => (
            <article key={index} 
              className="post type-post status-publish format-standard has-post-thumbnail hentry category-marketing category-sales category-social-media tag-brand-awareness tag-branding tag-customer-experience tag-customer-persona tag-social-media tag-social-media-branding tag-social-media-marketing entry"
              >
              <div className="container-fluid primary-color-bg">
                  <div className="container p-2">
                  <div className="row main-container-padding-b-1 main-container-padding-t-1">
                      <div className="col-md-12">
                      <div className="row">
                          <div className="col-md-12 text-left georgia-text">
                          <h2 data-aos="fade-up" className="article-page-heading" dangerouslySetInnerHTML={{__html: node.title}} />
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-12 text-left pb-2">
                          <p data-aos="fade-up" className="card-title text-primary-color text-lowercase secondary-font-medium m-0 ">
                              <span className="reduced-letter-spacing">{node.categories[0].name}</span>{" "}
                              |{" "}
                              <small className="text-muted primary-font captalise georgia-text ">
                              <time dateTime="2020-09-14T06:42:41+00:00">
                              {node.date}
                              </time>
                              </small>
                          </p>
                          </div>
                      </div>

                      <div data-aos="fade-up" className="entry-content text-primary-color" dangerouslySetInnerHTML={{ __html : node.excerpt }}></div>

                      <div className="row">
                          <div className="col-md-12" data-aos="fade-up">
                          <Link
                              to={'/' + node.slug + '/'}
                              className="button-primary-gray"
                          >
                              Read More&nbsp;&nbsp;
                              <span>
                              <i className="la la-angle-right"></i>
                              </span>
                          </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  <hr />
                  </div>
              </div>
          </article>
          ))}
        </main>
      </section>
    </PrimaryLayout>
  );

}

export default Tags;

export const query = graphql`
{
    wordpressPage(wordpress_id: {eq: 2408}) {
      acf {
        seo_title
        seo_keywords
        seo_description
      }
    }
    allWordpressPost {
      nodes {
        excerpt
        date(formatString: "MMMM D, Y")
        title
        categories {
          name
        }
        slug
        tags {
          slug
          name
        }
      }
    }
  }
`